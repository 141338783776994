<template>
  <div class="enterprise">
    <div class="enterprise-in">
      <div class="header">
        <div class="header-in">
          <div class="title">企业认领平台</div>
          <div class="userinfo">
            <div class="avatar">
              <img
                style="width: 100%"
                :src="userInfo.headPortrait || '../assets/mine/avatar.png'"
                alt=""
              />
            </div>
            <div class="info">
              <div class="tips">欢迎您：{{ userInfo.orgName }}</div>
              <div>
                <span>{{ userInfo.cractCode }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="tabs">
          <div
            class="tabItem"
            :class="tabIndex == 0 ? 'on' : ''"
            @click="changeTab(0)"
          >
            <div class="icon">
              <img src="../assets/enterprise/tab1.png" alt="" />
            </div>
            <div class="info">
              <span>待认领</span>
              <span class="num num1">{{ peddingOption.total }}</span>
              <span>家</span>
            </div>
          </div>
          <div
            class="tabItem"
            :class="tabIndex == 1 ? 'on' : ''"
            @click="changeTab(1)"
          >
            <div class="icon">
              <img src="../assets/enterprise/tab2.png" alt="" />
            </div>
            <div class="info">
              <span>已认领</span>
              <span class="num num2">{{ doneOption.total }}</span>
              <span>家</span>
            </div>
          </div>
        </div>
      </div>
      <div class="search-box">
        <div class="search-item" v-show="tabIndex == 0">
          <div class="search-label">待认领信息</div>
          <div class="search-val">
            <input
              type="text"
              @keydown.enter="fnSearch"
              v-model="search"
              placeholder="请输入统一信用代码、企业名称、企业地址"
            />
            <button @click="fnSearch">
              <span class="icon"></span>
              <span>搜索</span>
            </button>
          </div>
        </div>
        <div class="search-item" v-show="tabIndex == 1">
          <div class="search-label">已认领信息</div>
          <div class="search-val">
            <input
              @keydown.enter="fnSearch"
              type="text"
              v-model="search"
              placeholder="请输入统一信用代码、企业名称、企业地址"
            />
            <button @click="fnSearch">
              <span class="icon"></span>
              <span>搜索</span>
            </button>
          </div>
        </div>
      </div>
      <!-- 待认领列表 -->
      <van-pull-refresh
        v-if="tabIndex == 0"
        v-model="peddingOption.refreshing"
        @refresh="onRefresh"
      >
        <van-list
          v-model="peddingOption.loading"
          :finished="peddingOption.finished"
          finished-text="没有更多了"
          @load="onLoad"
          :error.sync="peddingOption.error"
          error-text="请求失败，点击重新加载"
        >
          <div class="list">
            <ul class="list-box">
              <li
                class="list-item"
                v-for="(item, index) in peddingOption.list"
                :key="index"
              >
                <div class="top">
                  <div class="top-item">
                    <div class="label">企业名称：</div>
                    <div class="value">{{ item.dmfdmSwqymc }}</div>
                  </div>
                  <div class="top-item">
                    <div class="label">统一信用代码：</div>
                    <div class="value">{{ item.dmfdmTyxydm }}</div>
                  </div>
                  <div class="top-item">
                    <div class="label">企业地址：</div>
                    <div class="value">{{ item.dmfdmZcdz }}</div>
                  </div>
                </div>
                <div class="footer">
                  <div class="footer-item back" @click="toBack(item)">退回</div>
                  <div class="footer-item claim" @click="toClaim(item)">
                    认领
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </van-list>
      </van-pull-refresh>
      <!-- 已认领列表 -->
      <van-pull-refresh
        v-if="tabIndex == 1"
        v-model="doneOption.refreshing"
        @refresh="onRefresh"
      >
        <van-list
          v-model="doneOption.loading"
          :finished="doneOption.finished"
          finished-text="没有更多了"
          @load="onLoad"
          :error.sync="doneOption.error"
          error-text="请求失败，点击重新加载"
        >
          <div class="list">
            <ul class="list-box">
              <li
                class="list-item"
                v-for="(item, index) in doneOption.list"
                :key="index"
              >
                <div class="top">
                  <div class="top-item">
                    <div class="label">企业名称：</div>
                    <div class="value">{{ item.dmfdmSwqymc }}</div>
                  </div>
                  <div class="top-item">
                    <div class="label">统一信用代码：</div>
                    <div class="value">{{ item.dmfdmTyxydm }}</div>
                  </div>
                  <div class="top-item">
                    <div class="label">企业地址：</div>
                    <div class="value">{{ item.dmfdmZcdz }}</div>
                  </div>
                </div>
                <div class="footer">
                  <div class="footer-item back" @click="toBack(item)">退回</div>
                  <!-- <div class="footer-item claim" @click="toClaim(item)">认领</div> -->
                </div>
              </li>
            </ul>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <!-- 手机号弹窗 -->
    <van-dialog
      v-model="show1"
      title="请输入手机号"
      show-cancel-button
      @cancel="show1 = false"
      @confirm="confirm"
    >
      <template>
        <van-field
          style="margin: 0 15px"
          v-model="phone"
          rows="1"
          placeholder="请输入留手机号"
        />
      </template>
    </van-dialog>
  </div>
</template>
<script>
import { getList, getcount, getUserInfo, getToken } from "../api/home";
import { mapMutations } from "vuex";
// import { mapState } from "vuex";
import { Toast } from "vant";
export default {
  data() {
    return {
      show1: false,
      phone: "",
      tabIndex: 0,
      search: "",
      peddingOption: {
        list: [],
        loading: false,
        finished: false,
        refreshing: false,
        page: 1,
        rcRlzt: 0, //待认领
        error: false,
        limit: 10,
        total: 0,
      },
      optionType: "peddingOption",
      doneOption: {
        list: [],
        loading: false,
        finished: false,
        rcRlzt: 1, //已认领
        refreshing: false,
        page: 1,
        error: false,
        limit: 10,
        total: 0,
      },
      userInfo: {
        orgName: "未登录",
        cractMobile: "未绑定",
        headPortrait: "../assets/mine/avatar.png",
      },
    };
  },
  mounted() {
    // let userInfo = this.$store.state.common.userInfo;
    // console.log('🚀 ~ file: enterprise-claim.vue ~ line 191 ~ mounted ~ userInfo', userInfo);
    var vuex = JSON.parse(localStorage.getItem("vuex"));
    // console.log(vuex, vuex != null);
    if (vuex == null) {
      // localStorage.removeItem("vuex");
      // this.$router.push({ path: "/mine" });
    } else {
      // this.userInfo = userInfo;
      this.getcount();
    }
    this.showPopup();
  },
  methods: {
    ...mapMutations({
      setToken: "common/setToken",
      setUser: "common/setUser",
    }),
    showPopup() {
      let userInfo = this.$store.state.common.userInfo;
      if (!userInfo) {
        this.show1 = true;
      } else {
        this.userInfo = userInfo;
        this.phone = this.userInfo.cractMobile;
        this.confirm(false);
      }
    },
    async confirm(bol = true) {
      //  执行此方法时需要判断 当前用户是否已经登录过， 如果登录则取之前登录的时候好佛则 重新输入登录
      var reg_tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (
        this.phone.replace(/\s+/g, "").length == 0 ||
        isNaN(Number(this.phone)) ||
        !reg_tel.test(this.phone)
      ) {
        // this.show1 = false;
        Toast.fail("请输入正确手机号");
        // this.show1 = true;
        return false;
      }
      try {
        let that = this;
        let p1 = await getToken({ sign: this.phone });
        that.setToken(p1.data);
        let p2 = await getUserInfo({ token: p1.data });
        let userInfo = (that.userInfo = p2.data);
        that.setUser(userInfo);
        if (bol) {
          Toast.success("登录成功");
          this.show1 = false;
        }
      } catch (err) {
        let that = this;
        Toast({
          type: "fail",
          message: "获取成功",
          onClose: () => {
            that.$router.go(-1);
          },
        });
      }
    },
    getcount() {
      getcount()
        .then((res) => {
          this.peddingOption.total = res.data.toBeClaimTotal;
          this.doneOption.total = res.data.beClaimTotal;
        })
        .catch((err) => {
          // console.log(err);
          Toast.fail("请求失败！");
        });
    },
    // 列表切换
    changeTab(tab) {
      //设置当前活动配置项并清空选择数组
      this.tabIndex = tab;
      this.optionType = tab == 0 ? "peddingOption" : "doneOption";

      this.search = "";
      this[this.optionType].list = [];
      this.onLoad();
    },
    // 下拉刷新
    onRefresh() {
      this[this.optionType].finished = true;
      this[this.optionType].page = 1;
      this[this.optionType].list = [];
      this.getList();
    },
    // 搜索
    fnSearch() {
      // console.log(111);
      this[this.optionType].finished = true;
      this[this.optionType].list = [];
      this[this.optionType].page = 1;
      this.getList();
    },
    //上拉加载
    onLoad() {
      this[this.optionType].finished = true;
      this.getList();
    },
    getList() {
      let target = this[this.optionType];
      let data = {
        start: target.page == 1 ? 0 : target.list.length,
        limit: target.limit,
        keyWords: this.search,
        rcRlzt: target.rcRlzt,
      };
      getList(data)
        .then((res) => {
          let count = res.total;
          let list = res.data;
          target.loading = false;
          target.refreshing = false;
          target.list = target.list.concat(list);
          // console.log(target, count);
          if (target.list.length >= count) {
            target.finished = true;
            return false;
          } else {
            target.finished = false;
          }
          target.page++;
        })
        .catch((err) => {
          target[err] = true;
          Toast.fail("数据获取失败，请刷新重试");
        });
    },
    toClaim(item) {
      // console.log(item);
      this.$router.push({
        path: "/detail",
        query: { item: JSON.stringify(item) },
      });
    },
    toBack(item) {
      // console.log(item);
      this.$router.push({
        path: "/back",
        query: { item: JSON.stringify(item) },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.enterprise {
  .enterprise-in {
    background: #f6f8fe;
    min-height: 100vh;
    .header {
      height: 2.36rem;
      background: url("../assets/mine/bg.png") no-repeat 0 0;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .header-in {
        width: 3.5rem;
        .title {
          font-size: 0.56rem;
          font-weight: bolder;
          color: #ffffff;
          margin-bottom: 0.17rem;
        }

        .userinfo {
          display: flex;
          text-align: left;
          // justify-content: space-between;
          .avatar {
            width: 0.9rem;
            height: 0.9rem;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 0.22rem;
            img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }
          .info {
            color: rgba(255, 255, 255, 0.8);
            .tips {
              margin: 0.1rem 0;
            }
          }
        }
      }
      .tabs {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -0.68rem;
        width: 7.1rem;
        height: 0.88rem;
        border-radius: 0.08rem;
        background: #fff;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
        display: flex;
        justify-content: space-around;
        align-content: center;
        .tabItem {
          width: 2.35rem;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .icon {
            width: 0.23rem;
            height: 0.263rem;
            img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }
          .info {
            font-size: 0.26rem;
            color: #333333;
            span {
              // margin-left: 0.16rem;
            }
            .num {
              font-size: 0.36rem;
            }
            .num1 {
              color: #ffa200;
            }
            .num2 {
              color: #1fc57e;
            }
          }
        }
        .on {
          position: relative;
          &::after {
            content: "";
            display: block;
            height: 0.15rem;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            background: url("../assets/enterprise/after.png") no-repeat 0 0;
            background-size: 100% 100%;
          }
        }
      }
    }
    .search-box {
      width: 7.1rem;
      margin: 0 auto;
      margin-top: 1rem;
      height: 0.5rem;
      .search-item {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .search-label {
          font-size: 0.3rem;
          color: #3a87ef;
          padding-left: 0.18rem;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            display: block;
            width: 0.05rem;
            height: 0.28rem;
            background: #3282ed;
            border-radius: 0.025rem;
          }
        }
        .search-val {
          display: flex;
          position: relative;
          border: 1px solid #e5e5e5;
          border-radius: 0.25rem;
          border-right: 0;
          overflow: hidden;
          input {
            display: block;
            width: 4.79rem;
            border: 0;
            outline: none;
            height: 0.5rem;
            padding-right: 1.2rem;
            padding-left: 0.2rem;
            box-sizing: border-box;
            // border-radius: 0.25rem 0 0 0.25rem;
            &::-webkit-input-placeholder {
              font-size: 0.18rem;
              color: #cccccc;
              text-align: center;
            }
          }
          button {
            position: absolute;
            right: 0;
            top: 0;
            display: flex;
            border: 0;
            outline: none;
            width: 1.15rem;
            height: 0.5rem;
            border-radius: 0.25rem;
            font-size: 0.26rem;
            color: #ffffff;
            align-items: center;
            justify-content: center;
            background-image: linear-gradient(-45deg, #3282ed 0%, #6aa9ff 100%);
            & > * {
              pointer-events: none;
            }
            .icon {
              display: block;
              width: 0.22rem;
              height: 0.22rem;
              background: url("../assets/enterprise/search.png") no-repeat 0 0;
              background-size: 100% 100%;
              margin-right: 00.1rem;
            }
          }
        }
      }
    }
    .list {
      width: 7.1rem;
      margin: 0 auto;

      margin-top: 0.5rem;
      .list-box {
        .list-item {
          list-style: none;
          text-align: left;
          background: #ffffff;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
          border-radius: 8px;
          margin-bottom: 0.3rem;
          .top {
            padding: 0.2rem;
            border-bottom: 1px solid #eee;
            .top-item {
              display: flex;
              align-items: flex-start;
              font-size: 0.24rem;
              margin-bottom: 0.16rem;
              .label {
                white-space: nowrap;
                color: #333333;
              }
              .value {
                color: #589df9;
              }
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          .footer {
            display: flex;
            .footer-item {
              flex: 1;
              text-align: center;
              width: 50%;
              height: 0.7rem;
              line-height: 0.7rem;
              font-size: 0.24rem;

              &:first-child {
                border-right: 1px solid #eee;
              }
            }
            .back {
              color: #eb3131;
            }
            .claim {
              color: #1fc57e;
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
